<template>
  <el-container
    class="h-full"
    v-if="selectedAccountId && selectedAccountId !== 'all'"
  >
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('QforMe')"
      :navItems="navItems"
      @navItemChanged="handleNavItemChanged"
    ></page-header>
    <component v-bind:is="component"></component>
  </el-container>
  <div v-else>
    <not-scoped scope="Account" resource="QforMe" />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import { mapState } from "vuex";
import NotScoped from "@/components/NotScoped";

export default {
  name: "QForMeIndex",
  components: {
    PageHeader,
    NotScoped
  },

  data() {
    return {
      selectedComponent: "queues",
      selectedIndex: null,
      navItems: [
        {
          label: __("Queues"),
          active: true,
          path: "queues",
          icon: "icon-queue.svg"
        },
        {
          label: __("Prompts"),
          active: false,
          path: "prompts",
          icon: "icon-prompts.svg"
        },
        {
          label: __("Waiters"),
          active: false,
          path: "waiters",
          icon: "icon-waiter.svg"
        },
        {
          label: __("Answered Call Log"),
          active: false,
          path: "answerlog",
          icon: "icon-answered-call-log.svg"
        }
      ]
    };
  },

  computed: {
    component() {
      return this.importComponent(this.selectedComponent);
    },
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    })
  },

  methods: {
    importComponent(path) {
      return () => import("@/views/build/qforme/" + path + "/index.vue");
    },

    handleNavItemChanged(navItem) {
      this.selectedComponent = navItem.path;
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      _.find(this.navItems, { label: navItem.label }).active = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
